<template>
	<transition>
		<div>
			<div>
				<DxDataGrid
					:ref="dataGrid.refName"
					:id="dataGrid.id"
					key-expr="subjId"
					:allow-column-reordering="true"
					:data-source="dataGrid.dataSource"
					:show-column-headers="true"
					:show-borders="false"
					:column-auto-width="true"
					:show-row-lines="true"
					:show-column-lines="false"
					:no-data-text="this.$_msgContents('CMN_NO_DATA')"
					:selected-row-keys="dataGrid.selectedRowKeys"
					@cell-hover-changed="onCellHoverChanged"
					@row-click="onRowClick"
					@selection-changed="onSelectionChanged"
					height="450"
				>
					<DxScrolling mode="virtual" />
					<DxSelection mode="multiple" />
					<DxFilterRow :visible="true" />
					<DxHeaderFilter :visible="true" />

					<DxColumn
						caption="강의명"
						data-field="eduNm"
						alignment="left"
						:allow-header-filtering="false"
						:allow-sorting="true"
					></DxColumn>
					<DxColumn
						caption="진행방식"
						data-field="divisionCd"
						alignment="center"
						width="140"
						:allow-header-filtering="true"
						:allow-sorting="true"
						:calculate-sort-value="sortByDivisionCd"
					>
						<DxLookup
							:data-source="this.$_enums.ewm.eduEducationProcessDivision.values"
							value-expr="value"
							display-expr="label"
						/>
					</DxColumn>
					<DxColumn
						caption="소요시간"
						data-field="eduTime"
						alignment="center"
						:allow-filtering="false"
						:allow-header-filtering="false"
						:allow-sorting="true"
						:calculate-display-value="calculateEduTime"
						width="140"
					>
					</DxColumn>
					<DxColumn
						caption="강의유형"
						data-field="typeCd"
						alignment="center"
						:allow-header-filtering="true"
						:allow-sorting="true"
						width="190"
						:calculate-sort-value="sortByTypeCd"
					>
						<DxLookup :data-source="codes.typeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
					</DxColumn>
					<DxColumn
						caption="강사 [ID]"
						data-field="teachers"
						alignment="center"
						cell-template="teacherTemplate"
						:allow-filtering="false"
						:allow-header-filtering="false"
						:allow-sorting="false"
						width="170"
					>
					</DxColumn>
					<DxColumn
						caption="첨부파일"
						data-field="attacheFiles"
						alignment="center"
						:allow-filtering="false"
						:allow-header-filtering="false"
						:allow-sorting="false"
						width="100"
						cell-template="downloadCellTemplate"
					>
					</DxColumn>

					<template #teacherTemplate="{ data }">
						<div v-if="data.row.data.teachers.length == 1">
							{{ data.row.data.teachers[0].teacherName }}
							[{{ data.row.data.teachers[0].teacherId }}]
						</div>
						<div v-else-if="data.row.data.teachers.length > 1">{{ data.row.data.teachers.length }}명</div>
					</template>

					<template #downloadCellTemplate="{ data }">
						<div v-if="data.value.length > 0">
							<DxButton class="download" @click="downloadFile(data.value)" />
						</div>
					</template>
				</DxDataGrid>
			</div>
			<div>
				<DxPopover width="auto" :visible="defaultVisible" :target="hoverTarget" position="top">
					<div v-html="popover_description"></div>
				</DxPopover>
			</div>
		</div>
	</transition>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDataGrid, DxColumn, DxLookup, DxScrolling, DxSelection, DxFilterRow, DxHeaderFilter } from 'devextreme-vue/data-grid';
import { DxPopover } from 'devextreme-vue/popover';
import { isSuccess } from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";

let vm = this;

export default {
	components: {
		DxButton,

		DxDataGrid,
		DxColumn,
		DxLookup,
		DxScrolling,
		DxSelection,
		DxFilterRow,
		DxHeaderFilter,

		DxPopover,
	},
	props: {
		contentData: {
			default: () => {
				return [];
			},
			type: Array,
		},
	},
	watch: {},
	data() {
		return {
			defaultVisible: false,
			popover_caption: '',
			popover_description: '',
			hoverTarget: '',
			codes: {
				typeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			attachFileUrl: {
				fileUpload: 'COMMON_ATTACHED_FILE_UPLOAD',
				fileDownload: 'COMMON_ATTACHED_FILE_DOWNLOAD',
				fileDelete: 'COMMON_ATTACHED_FILE_DELETE',
			},
			selectedList: [],
			dataGrid: {
				id: 'educationList',
				refName: 'educationList',
				dataSource: [],
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			isSelectionStopped: true,
		};
	},
	computed: {
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
		/** @description : 소트설정 */
		sortByDivisionCd(rowData) {
			return this.$_enums.ewm.eduEducationProcessDivision.values.find(e => rowData.divisionCd == e.value).label;
		},
		sortByTypeCd(rowData) {
			return this.codes.typeCd.dataSource.find(e => rowData.typeCd == e.codeId).codeNm;
		},
		/** @description : 강사컬럼 마우스 hover 이벤트 */
		onCellHoverChanged(e) {
			this.defaultVisible = false;
			this.hoverTarget = e.cellElement;
			if (e.rowType === 'data' && e.eventType === 'mouseover') {
				if (e.column.dataField == 'teachers') {
					if (e.data.teachers.length > 0) {
						let teacherText = '';
						e.data.teachers.forEach(val => (teacherText += `<li>${val.teacherName}[${val.teacherId}]</li>`));
						this.popover_caption = '강사';
						this.popover_description = `<ul class="teacher-list">${teacherText}</ul>`;
						this.defaultVisible = true;
					}
				}
			}

			const event = e.event;
			if (event.buttons === 1) {
				if (this.isSelectionStopped) {
					this.isSelectionStopped = false;
					this.selectedRange = {};
				}

				if (this.selectedRange.startRowIndex === undefined) {
					this.selectedRange.startRowIndex = e.rowIndex;
				}

				if (!this.selectedRange.startColumnIndex) {
					this.selectedRange.startColumnIndex = e.columnIndex;
				}

				this.selectedRange.endRowIndex = e.rowIndex;
				this.selectedRange.endColumnIndex = e.columnIndex;

				let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
				let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

				let indexes = [];
				for (let i = start; i <= end; i++) {
					indexes.push(i);
				}
				e.component.selectRowsByIndexes(indexes);
			} else {
				this.isSelectionStopped = true;
			}
		},
		onRowClick(e) {
			let keys = e.component.getSelectedRowKeys();
			let index = keys.indexOf(e.key);

			if (index > -1) {
				keys.splice(index, 1);
			} else {
				keys.push(e.key);
			}

			e.component.selectRows(keys);
		},
		calculateEduTime(rowData) {
			const pad = num => num.toString().padStart(2, '0');
			return `${pad(Math.floor(rowData.eduTime / 60))}:${pad(rowData.eduTime % 60)}`;
		},
		onSelectionChanged({ selectedRowsData }) {
			const data = selectedRowsData;
			this.$emit('input', data);
		},
		downloadFile(data) {
			const fileGroupId = data[0].groupId;
			const fileName = data[0].fileName;

			const token = this.$store.getters.getAccessToken;

			const downloadUrl = `${this.attachFileUrl.fileDownload}/${fileGroupId}/${fileName}`;

			const requestOptions = {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			};

			fetch(downloadUrl, requestOptions)
				.then(response => {
					if (!response.ok) {
						this.$_Toast('다운로드 오류 발생');
						throw new Error('response error');
					}
					return response.blob();
				})
				.then(blob => {
					const url = window.URL.createObjectURL(blob);

					console.log('url', url);
					const a = document.createElement('a');
					a.href = url;
					a.download = data[0].originalFileFullName;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				})
				.catch(error => {
					console.error('file download error:', error);
					this.$_Toast('다운로드 오류 발생');
				});
		},
		/** @description: 데이터 조회 메서드 */
		async selectDataList() {
			this.dataGrid.dataSource = new CustomStore({
				key: 'subjId',
				async load(loadOptions) {
					let payload = {
						actionname: 'EWM_EDUCATION_SUBJECT_LIST',
						data: {
							isAll: 'Y',
						},
						loading: false,
						useErrorPopup: true,
					};
					const res = await vm.CALL_EWM_API(payload);
					if (isSuccess(res)) {
						return res.data.data;
					}
				},
			});
		},
		/** @description : 코드 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_edu_education_type');
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			vm = this;

			this.selectedList = this.contentData;

			this.initCodeMap().then(() => {
				this.codes.typeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_type'], 2);
			});

			this.selectDataList();

			if (this.selectedList.length > 0) {
				this.dataGrid.selectedRowKeys = this.selectedList;
			} else {
				this.selectedList = [];
			}
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			const apiFileUpload = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_UPLOAD');
			const apiFileDownload = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DOWNLOAD');
			const apiFileDelete = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DELETE');

			/* this.attachFileUrl.fileUpload = apiFileUpload.apiserver.serverurl + apiFileUpload.url;
			this.attachFileUrl.fileDownload = apiFileDownload.apiserver.serverurl + apiFileDownload.url;
			this.attachFileUrl.fileDelete = apiFileDelete.apiserver.serverurl + apiFileDelete.url; */

			this.attachFileUrl.fileUpload = apiFileUpload.host + apiFileUpload.path;
			this.attachFileUrl.fileDownload = apiFileDownload.host + apiFileDownload.path;
			this.attachFileUrl.fileDelete = apiFileDelete.host + apiFileDelete.path;
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
	destroyed() {},
};
</script>
